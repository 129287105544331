body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
      171.71deg,
      rgba(255, 255, 255, 0.93) 10.18%,
      rgba(255, 255, 255, 0.773779) 32.87%,
      rgba(255, 255, 255, 0.55) 62.73%,
      rgba(255, 255, 255, 0.78) 89.42%
    ),
    linear-gradient(90deg, #9852fc 0%, #fc55d0 33%, #ff8b65 66%, #ffeb2b 100%);
  background-attachment: fixed;
}
